const Video = require('twilio-video')

const VideoUI = {
  createParticipantView: (sid, identity) => {
    $('#screen .participants').append(`<div id="${sid}" data-name="${identity}" class="participant"></div>`)
    return $(`#${sid}`)
  },

  removeParticipantView: (sid) => {
    $(`#${sid}`).remove()
  }
}

var elem = document.documentElement;


/* View in fullscreen */
function openFullscreen() {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) { /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE/Edge */
    elem.msRequestFullscreen();
  }
}

/* Close fullscreen */
function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) { /* Firefox */
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) { /* IE/Edge */
    document.msExitFullscreen();
  }
}

const Controls = {
  localVideo: null,
  localAudio: null,

  toggle: (event) => {
    $(event.target).toggleClass('clicked')
    return $(event.target).hasClass('clicked')
  },

  togglePreview: (event) => {
    if ($("#preview-icon").hasClass('fa-user-slash')) {
      $('#preview').hide()
      $("#preview-icon").addClass('fa-user')
      $("#preview-icon").removeClass('fa-user-slash')
    } else {
      $('#preview').show()
      $("#preview-icon").removeClass('fa-user')
      $("#preview-icon").addClass('fa-user-slash')
      }
    event.preventDefault()
  },

  toggleMic: (event) => {
    if ($("#mic-icon").hasClass('fa-microphone')) {
      Controls.localAudio.disable()
      $("#mic-icon").addClass('fa-microphone-slash')
      $("#mic-icon").removeClass('fa-microphone')
    } else {
      Controls.localAudio.enable()
      $("#mic-icon").removeClass('fa-microphone-slash')
      $("#mic-icon").addClass('fa-microphone')
    }
    event.preventDefault()
  },

  toggleCam: (event) => {
    if ($("#cam-icon").hasClass('fa-video')) {
      Controls.localVideo.disable()
      $("#cam-icon").addClass('fa-video-slash')
      $("#cam-icon").removeClass('fa-video')
    } else {
      Controls.localVideo.enable()
      $("#cam-icon").removeClass('fa-video-slash')
      $("#cam-icon").addClass('fa-video')
    }
    event.preventDefault()
  },
  
  toggleFullscreen: (event) => {
    if ($("#screen-icon").hasClass('fa-expand')) {
      openFullscreen()
      $("#screen-icon").addClass('fa-compress')
      $("#screen-icon").removeClass('fa-expand')
    } else {
      closeFullscreen()
      $("#screen-icon").removeClass('fa-compress')
      $("#screen-icon").addClass('fa-expand')
    }
    event.preventDefault()
  },

  toggleSound: (event) => {
    console.log('todo...')
    event.preventDefault()
  },

  toggleInvitationLink: (event) => {
    console.log('xxx')
    $('#invitation-link').toggleClass('d-none')
    $('#invitation-link-expand').toggleClass('d-none')
  },

  setup: function() {
    $('#toggle-preview').on('click', this.togglePreview)
    $('#toggle-mic').on('click', this.toggleMic)
    $('#toggle-cam').on('click', this.toggleCam)
    $('#toggle-sound').on('click', this.toggleSound)
    $('#toggle-fullscreen').on('click', this.toggleFullscreen)
    $('#toggle-invitation-link').on('click', this.toggleInvitationLink)
    $('#invitation-link-expand').on('click', this.toggleInvitationLink)
  }
}

window.addEventListener('load', function () {
  const config = $('#video-config')
  if (config.length) {

    Controls.setup()

    Video.connect(config.data('token'), { name: config.data('room'), audio: false, video: false }).then(room => {
      console.log('Connected to Room "%s"', room.name);
      Video.createLocalTracks().then(tracks=>{

        Controls.localVideo = tracks.find(track => track.kind === 'video');
      room.localParticipant.publishTrack(Controls.localVideo);
      $('#local-video').append(Controls.localVideo.attach());
      

      Controls.localAudio = tracks.find(track => track.kind === 'audio');
        
      room.localParticipant.publishTrack(Controls.localAudio);
      

        })
      room.participants.forEach(participantConnected);
      room.on('participantConnected', participantConnected);

      room.on('participantDisconnected', participantDisconnected);
      room.once('disconnected', error => room.participants.forEach(participantDisconnected));
    });
    
    var timedelay = 1;
        var _delay = setInterval(delayCheck, 7500);
        $(document).on('mousemove  touchstart','#screen',function(e){
            clearInterval(_delay);
            $('#video-controls').fadeIn();
            timedelay = 1;
            _delay = setInterval(delayCheck, 3300);
        });

        function delayCheck() {
            if (timedelay == 3) {
                $('#video-controls').fadeOut();
                timedelay = 1;
            }
            timedelay = timedelay + 1;
        }
    function checkTime(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

    function startTime() {
      var today = new Date();
      var h = today.getHours();
      var m = today.getMinutes();
      var s = today.getSeconds();
      // add a zero in front of numbers<10
      m = checkTime(m);
      s = checkTime(s);
      document.getElementById('time').innerHTML = h + ":" + m + ":" + s;
      t = setTimeout(function() {
      startTime()
    }, 500);
  }
  startTime();
  
    function hideTime() {
        if ($("#screen").hasClass('patient')) {
        $('#time').hide()
       }
    }
    hideTime();
    
    function participantConnected(participant) {
      $.ajax({
        url: window.location.href,
        type: 'PUT'
      });

      console.log('Participant "%s" connected', participant.identity);

      const element = VideoUI.createParticipantView(participant.sid, participant.identity)

      participant.on('trackSubscribed', track => trackSubscribed(element, track));
      participant.on('trackUnsubscribed', trackUnsubscribed);

      participant.tracks.forEach(publication => {
        if (publication.isSubscribed) {
          trackSubscribed(element, publication.track);
        }
      });
    }

    function participantDisconnected(participant) {
      console.log('Participant "%s" disconnected', participant.identity);
      VideoUI.removeParticipantView(participant.sid)
    }

    function trackSubscribed(div, track) {
      div.append(track.attach());
    }

    function trackUnsubscribed(track) {
      track.detach().forEach(element => element.remove());
    }
  }
})
