// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("bootstrap/dist/js/bootstrap")
require("video")

const { UI } = require("../utils")

$(function() {
  function toggleDeleteUsersButton() {
    if ($('.select-user:checkbox:checked').length > 0) {
      $('#delete-selected-users').removeClass('invisible')
    } else {
      $('#delete-selected-users').addClass('invisible')
    }
  }

  $('#toggle_all_checkboxes').on('click', function() {
    const isChecked = $(this).is(':checked')
    const target = $(this).data('target')
    $(`.${target}:checkbox`).each(function() {
      $(this).prop('checked', isChecked)
    })
    toggleDeleteUsersButton()
  })

  $('.select-user:checkbox').on('click', function() {
    toggleDeleteUsersButton()
  })

  $('#delete-selected-users').on('click', function(event) {
    event.preventDefault()
    const selectedUserIds = []
    $('.select-user:checkbox:checked').each(function() {
      selectedUserIds.push($(this).prop('value'))
    })
    console.log(selectedUserIds.join(','))
    $('#selected-user-ids').val(selectedUserIds.join(','))

    $(this).submit()
  })

  var as = $('#auto-submit')
  if (as) {
    as.submit()
  }
  $('.copy-to-clipboard').on('click', function() {
    const el = $(`#${$(this).data('source-id')}`)
    el.select()
    document.execCommand('copy')
  })
  $('.therapy-session-updates').each(function()
    {
      const slug = $(this).data('slug')
      const patientOnline = $(this).data('patient-online')
      const therapistOnline = $(this).data('therapist-online')
      if (slug) {
        UI.showElementsFor(slug, 'patient', `${patientOnline}` == 'true')
        UI.showElementsFor(slug, 'therapist', `${therapistOnline}` == 'true')
      }
    }
  )
})
