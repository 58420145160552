import consumer from "./consumer"

const { UI } = require("../utils")

$(function() {
  $('.therapy-session-updates').each(function()
    {
      const instanceId = Math.random().toString(20).substr(2)
      const slug = $(this).data('slug')
      if (slug) {
        consumer.subscriptions.create({
          channel: "TherapySessionChannel",
          page: $(this).data('page'),
          instance_id: instanceId,
          ts_slug: slug
        }, {
          connected() {
          },

          disconnected() {
          },

          received(data) {
            if (data.state === 'patient_online') {
              const toast = $('#toast-patient-online')
              if (toast.length) {
                toast.find('.patient-name').text(data.patient_name)
                $('#toast-patient-online').toast('show')
                UI.playAudio('notification')
              }

              UI.showElementsFor(slug, 'patient', true)
            }

            if (data.state == 'patient_offline') {
              UI.showElementsFor(slug, 'patient', false)
            }
            if (data.state === 'therapist_online') { UI.showElementsFor(slug, 'therapist', true) }
            if (data.state === 'therapist_offline') { UI.showElementsFor(slug, 'therapist', false) }
            if (data.state === 'other_tab_opened') {
              // TODO: check if this is even relevant... maybe use different channel
              if (data.instance_id !== instanceId) {
                // alert('session was opened in another tab.')
              }
            }
          }
        })
      }
    })
})
