const UI = {
  showElementsFor: (slug, entity, online) => {
    $(`.ts-${slug} .${entity}-${online ? 'online' : 'offline'}`).show()
    $(`.ts-${slug} .${entity}-${!online ? 'online' : 'offline'}`).hide()
  },

  playAudio: (name) => {
    const audio = $(`#audio-${name}`)
    if (audio.length) {
      audio.get(0).play()
    }
  }
}

export { UI }
